import React from "react";
import { useParams } from "react-router-dom";
import SliderContainer from "../components/SliderContainer";
import EventStepCard from "../components/EventStepCard";
import NotFound from "../components/404NotFound";

function EventStepsPage(props) {
    const { content, events } = { ...props };
    const params = useParams();
    const id = params.id;
    const step_type_id = params.step_type_id;
    const event = events.find(event => event.id === id);
    const type = content.types.find(t => t.id === step_type_id);
    const steps = content.steps;
    if(!event || !type || !(id in steps) || !(step_type_id in steps[id])) {
        return <NotFound/>
    }
    const allStepsOfType = steps[id][step_type_id];
    return (
        <SliderContainer initial={0}>
            {allStepsOfType.map((step, idx) => <EventStepCard key={idx} step={step} type={type.name} total={allStepsOfType.length} event={event.title}/>)}
        </SliderContainer>
    )
}

export default EventStepsPage;