import React from "react";

function NotFound() {
    return (
        <div style={{display:"flex",flexDirection:"column",height:"100vh",alignItems:"center",justifyContent:"center",color:"gray"}}>
            <h1 style={{fontSize:"3em"}}>404</h1>
            <p style={{fontSize:"2em"}}>The page you are looking for doesn't exist.</p>
        </div>
    )
}

export default NotFound;