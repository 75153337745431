import React from 'react';


function NotificationPopup(props) {
    const {show, setShow} = {...props}
    return (
        <>
            {show &&
                <div className="notification-popup">
                    <p>You currently have notifications disabled.  To allow this application to work offline, enable notifications for this site in your browser's settings.</p>
                    <button onClick={() => {setShow(false)}}>OK</button>
                </div>
            }
        </>
    )
}

export default NotificationPopup;