import React from "react";
import { ThreeDots } from "react-loader-spinner";
import logo from "../assets/images/udot-logo-512x140.png"

function LoadingScreen() {
    return (
        <div className="loading">
            <img src={logo} width="250" height="68" alt="UDOT Logo"/>
            <ThreeDots color="#00BFFF" height={80} width={80}/>
        </div>
    )
}

export default LoadingScreen;