import React from "react";
import { useParams } from "react-router-dom";
import SliderContainer from "../components/SliderContainer";
import AnnexCard from "../components/AnnexCard";

function AnnexDetailPage(props) {
    const { annexes, appendices } = { ...props };
    const mergedSections = [...annexes.sections, ...appendices.appendix_sections];
    const params = useParams();
    const sectionType = params.section_type
    const id = params.id;
    let initialIndex = 0;
    if(sectionType === "annex_section") {
        let section = annexes.sections.find(section => section.id === id);
        if(section) {
            initialIndex = mergedSections.indexOf(section);
        }
    }
    else if(sectionType === "appendix_section") {
        let section = appendices.appendix_sections.find(section => section.id === id);
        if(section) {
            initialIndex = mergedSections.indexOf(section);
        }
    }

    return (
        <SliderContainer initial={initialIndex}>
            {mergedSections.map((section, idx) => <AnnexCard key={idx} section={section} />)}
        </SliderContainer>
    )
}

export default AnnexDetailPage;