import React from 'react';
import { getToken } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import firebaseConfig from './firebaseConfig';
import NotificationPopup from './components/NotificationPopup';
import HomePage from './pages/HomePage';
import EOPPage from './pages/EOPPage';
import EventsPage from './pages/EventsPage';
import EventDetailPage from './pages/EventDetailPage';
import LoadingScreen from './components/LoadingScreen';
import Navbar from './components/Navbar';
import EventStepsPage from './pages/EventStepsPage';
import RolesPage from './pages/RolesPage';
import RoleDetailPage from './pages/RoleDetailPage';
import ChecklistStepsPage from './pages/ChecklistStepsPage';
import AnnexPage from './pages/AnnexPage';
import AnnexDetailPage from './pages/AnnexDetailPage';
import FeedbackButton from './components/FeedbackButton';


function App() {
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
  const [showNotificationPopup, setShowNotificationPopup] = React.useState(false);
  const [siteData, setSiteData] = React.useState();
  const [homePageContent, setHomePageContent] = React.useState();
  const [updatedDate, setUpdatedDate] = React.useState();
  const [eopContent, setEopContent] = React.useState();
  const [eventsContent, setEventsContent] = React.useState();
  const [eventStepContent, setEventStepContent] = React.useState();
  const [rolesContent, setRolesContent] = React.useState();
  const [checklistsContent, setChecklistsContent] = React.useState();
  const [annexContent, setAnnexContent] = React.useState();
  const [appendixContent, setAppendixContent] = React.useState();

  async function requestNotificationPermission() {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission();
    if(permission === "granted") {
      const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY });
      //register device with firebase messaging
      await fetch(`/api/register_device`, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({token: token})
      });
    } 
    else if(permission === "denied") {
      setShowNotificationPopup(true)
    }
  }

  //try to get permission for persistent storage, so that browser won't clear cached content under storage pressure
  async function requestPersistentStorage() {
    if(navigator.storage && navigator.storage.persist) {
      navigator.storage.persist().then((persistent) => {
        if(persistent) {
          console.log("Storage will not be cleared except by explicit user action");
        } 
        else {
          console.log("Storage may be cleared by the UA under storage pressure.");
        }
      });
    }
  }

  //fetch site content from API and put in cache
  async function cacheNewData() {
    const res = await fetch(`/api/eop_data`);
    const clonedRes = res.clone();
    const json = await res.json();
    setSiteData(json);
    const cache = await caches.open("json-cache");
    cache.put("/site-data.json", clonedRes);
  }

  //get cached content if available, otherwise fetch neww content
  async function fetchData() {
    const dataInCache = await caches.match("/site-data.json");
    if(dataInCache) {
      const json = await dataInCache.json();
      setSiteData(json);
      return;
    }
    await cacheNewData();
  }

  //initial app load
  React.useEffect(() => {
    requestNotificationPermission();
    requestPersistentStorage();
    const messaging = getMessaging();
    //when a firebase message is received while app is in foreground, fetch new data from API and cache it
    onMessage(messaging, (payload) => {
      console.log("New data available, updating caches..."); 
      cacheNewData()
    });
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //split data into the content that each page will use
  React.useEffect(() => {
    if(siteData) {
      setHomePageContent(siteData.page_data[0]?.home_page_content);
      setUpdatedDate(siteData.page_data[0]?.last_updated);
      setEopContent(siteData.page_data[0]?.eop_content);
      setEventsContent(siteData.events);
      setEventStepContent(siteData.event_steps);
      setRolesContent(siteData.roles);
      setChecklistsContent(siteData.checklists);
      setAnnexContent(siteData.annexes);
      setAppendixContent(siteData.appendices);
    }
  }, [siteData])
  
  if(!(homePageContent && eopContent && eventsContent && eventStepContent && rolesContent && checklistsContent && appendixContent && annexContent)) {
    return <LoadingScreen/>
  }

  return (
    <div className="App">
      <NotificationPopup show={showNotificationPopup} setShow={setShowNotificationPopup}/>
      <BrowserRouter>
        <FeedbackButton/>
        <Routes>
          <Route path="/" element={<HomePage content={homePageContent} updatedDate={updatedDate}/>} />
          <Route path="eop" element={<EOPPage content={eopContent}/>} />
          <Route path="/events" element={<EventsPage content={eventsContent}/>} />
          <Route path="events/:id" element={<EventDetailPage content={eventsContent} steps={eventStepContent}/>} />
          <Route path="events/:id/steps/:step_type_id" element={<EventStepsPage content={eventStepContent} events={eventsContent}/>} />
          <Route path="/roles" element={<RolesPage content={rolesContent} />} />
          <Route path="/roles/:id" element={<RoleDetailPage content={rolesContent} checklists={checklistsContent}/>} />
          <Route path="/roles/:id/checklists/:checklist_type_id" element={<ChecklistStepsPage content={checklistsContent} roles={rolesContent}/>} />
          <Route path="/annex" element={<AnnexPage annexes={annexContent} appendices={appendixContent} />} />
          <Route path="/annex/:section_type/:id" element={<AnnexDetailPage annexes={annexContent} appendices={appendixContent} />} />
        </Routes>
        <Navbar/>
      </BrowserRouter>
    </div>
  );
}

export default App;
