import React from "react";
import { useParams } from "react-router-dom";
import SliderContainer from "../components/SliderContainer";
import RoleCard from "../components/RoleCard";

function RoleDetailPage(props) {
    const { content, checklists } = { ...props };
    const params = useParams();
    const id = params.id;
    const role = content.find(role => role.id === id);
    const initialIndex = role ? content.indexOf(role) : 0;

    return (
        <SliderContainer initial={initialIndex}>
            {content.map((role, idx) => <RoleCard key={idx} role={role} checklists={checklists}/>)}
        </SliderContainer>
    )
}

export default RoleDetailPage;