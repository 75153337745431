import React from "react";
import AnnexBar from "../components/AnnexBar";

function AnnexPage(props) {
    const { annexes, appendices } = { ...props };

    return (
        <div className="annex">
            {annexes.annexes.map(annex => {
                return (
                    <div key={annex.letter}>
                        <AnnexBar content={annex} contentType="annex"/>
                        {annexes.sections.filter(section => section.annex_letter === annex.letter).map(section => <AnnexBar key={section.id} content={section} contentType="section"/>)}
                    </div>
                )
            })}
            {appendices.appendices.map(appendix => {
                return (
                    <div key={appendix.number}>
                        <AnnexBar content={appendix} contentType="appendix" />
                        {appendices.appendix_sections.filter(section => section.appendix_number === appendix.number).map(section => <AnnexBar key={section.id} content={section} contentType="section"/>)}
                    </div>
                )
            })}
        </div>
    )
}

export default AnnexPage;