import React from "react";
import { Link } from "react-router-dom";

function RoleCard(props) {
    const { role, checklists } = { ...props };
    const checklistTypes = checklists.types.filter(el => role.id in checklists.lists && el.id in checklists.lists[role.id]);
    return (
        <div className="role-card slider-card center">
            <h3>{role.title}</h3>
            <div>
                <div className="icons-container">
                    {checklistTypes.map(t =>
                        <Link key={t.id} to={`/roles/${role.id}/checklists/${t.id}`}>
                            <div> 
                                <img src={`data:image/png;base64,${t.icon}`} alt={`${role.title} ${t.name} icon`}/>
                                <h6>{t.name}</h6>
                            </div>
                        </Link> 
                    )}
                </div>
                <div dangerouslySetInnerHTML={{__html: role.content}}></div>
            </div>
        </div>
    )
}

export default RoleCard;