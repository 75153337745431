import React from "react";
import eopPageHeaderImage from "../assets/images/eop-page-header-image.png";

function EOPPage(props) {
    const { content } = {...props}
    const headerImage = `<div class="image-wrapper"><img src=${eopPageHeaderImage} alt="UDOT EOP header image"/></div>`
    return (
        <div className="site-content" dangerouslySetInnerHTML={{__html: headerImage + content}}></div>
    )
}

export default EOPPage;