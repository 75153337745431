import React from "react";
import RoleBar from "../components/RoleBar";

function RolesPage(props) {
    const { content } = { ...props };
    return (
        <div className="roles">
            {content.map(role => <RoleBar key={role.id} role={role} />)}
        </div>
    )
}

export default RolesPage;