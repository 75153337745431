import React from "react";
import { useParams } from "react-router-dom";
import SliderContainer from "../components/SliderContainer";
import ChecklistStepCard from "../components/ChecklistStepCard";
import NotFound from "../components/404NotFound";

function ChecklistStepsPage(props) {
    const { content, roles } = { ...props };
    const params = useParams();
    const id = params.id;
    const checklist_type_id = params.checklist_type_id;
    const role = roles.find(role => role.id === id);
    const type = content.types.find(t => t.id === checklist_type_id);
    const lists = content.lists;
    if(!role || !type || !(id in lists) || !(checklist_type_id in lists[id])) {
        return <NotFound/>
    }
    const checklist = lists[id][checklist_type_id];
    return (
        <SliderContainer initial={0}>
            {checklist.map((step, idx) => <ChecklistStepCard key={idx} step={step} type={type.name} total={checklist.length} title={role.title}/>)}
        </SliderContainer>
    )
}

export default ChecklistStepsPage;