import React from 'react';
import Slider from 'react-slick';
import { useRef, useEffect } from 'react';

function SliderContainer(props) {
    const ref = useRef(0);

    const onChange = (current, next) => {
        const linksInCurrentSlide = ref.current.innerSlider.list
                                    .querySelector(`[data-index="${current}"]`)
                                    .querySelectorAll("a");
        linksInCurrentSlide.forEach(el => el.setAttribute("tabindex", -1));
        const linksInNextSlide = ref.current.innerSlider.list
                            .querySelector(`[data-index="${next}"]`)
                            .querySelectorAll("a");
        linksInNextSlide.forEach(el => el.setAttribute("tabindex", 0));
    }

    const settings = {
        dots: false,
        infinite: false,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed:500,
        initialSlide:props.initial,
        arrows:true,
        adaptiveHeight:true,
        beforeChange: onChange
    };

    useEffect(() => {
        ref.current.innerSlider.list.setAttribute("tabindex", 0);
        ref.current.innerSlider.list.focus();
        const linksInInitialSlide = ref.current.innerSlider.list
                                    .querySelector(`[data-index="${props.initial}"]`)
                                    .querySelectorAll("a");
        linksInInitialSlide.forEach(el => el.setAttribute("tabindex", 0));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="site-content center">
            <div className="slider-container">
                <Slider {...settings} ref={ref}>
                    {props.children}
                </Slider>
            </div>
        </div>
    )
}

export default SliderContainer;
