import React from "react";

function AnnexCard(props) {
    const { section } = { ...props };
    return (
        <div className="slider-card center">
            <h3>{section.title}</h3>
            <div>
                {section.annex_letter && <p>{section.annex_letter}.{section.section_number}</p>}
                {section.document_location && <p>Document Location: {section.document_location}</p>}
                <div dangerouslySetInnerHTML={{__html: section.content}}></div>
            </div>
        </div>
    )
}

export default AnnexCard;