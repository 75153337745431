import React from "react";
import NavbarButton from "./NavbarButton";

function Navbar() {
    return (
        <nav>
            <NavbarButton path="/" label="Home"/>
            <NavbarButton path="/eop" label="EOP"/>
            <NavbarButton path="/events" label="Events"/>
            <NavbarButton path="/roles" label="Roles"/>
            <NavbarButton path="/annex" label="Annex"/>
        </nav>
    )
}

export default Navbar;