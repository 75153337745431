import React from "react";
import slideshow from "../assets/images/home-page-slideshow.gif";

function HomePage(props) {
    const { content, updatedDate } = {...props}
    return (
        <div className="site-content">
            <div className="home-page-slideshow-container">
                <img src={slideshow} alt="Home Page Slideshow" width="478" height="330"/>
            </div>
            <p>Last Updated {(new Date(updatedDate)).toLocaleDateString()}</p>
            <a href="https://drive.google.com/file/d/12nsgjqFYTBSH2bFgUlaJ3JcM9v8LBbsL/view" target="_blank" rel="noreferrer">Link To EOP Document</a>
            <div dangerouslySetInnerHTML={{__html: content}}></div>
        </div>
    )
}

export default HomePage;