import React from "react";
import { Link } from "react-router-dom";

function EventBar(props) {
    const { event } = { ...props };
    return (
        <Link to={`/events/${event.id}`}>
            <div className="event-bar">
                <div>
                    <img src={`data:image/png;base64,${event.icon}`} alt={`${event.title} Icon`}/>
                    <div>
                        <h3>{event.title}</h3>
                        <p>{event.appendix_section}</p>
                    </div>
                </div>
                <div>

                </div>
            </div>
        </Link>
    )
}

export default EventBar;