import React from "react";

function EventStepCard(props) {
    const { step, type, total, event } = { ...props };
    return (
        <div className="slider-card center">
            <h4>EVENTS</h4>
            <h3>{event}</h3>
            {parseInt(step.step_number) < total &&
                <p className="next-step">Swipe for next step</p>
            }
            <div>
                <h6>ACTION</h6>
                <p>{type}</p>
                <h6>STEP</h6>
                <p>{step.step_number} OF {total}</p>
                <h6>ACTIONS</h6>
                <div dangerouslySetInnerHTML={{__html: step.actions}}></div>
                {step.hyperlink.replace(/<\/?[^>]+(>|$)/g, "") !== "" &&
                    <>
                        <h6>HYPERLINK</h6>
                        <div dangerouslySetInnerHTML={{__html: step.hyperlink}}></div>
                    </>
                }
            </div>
        </div>
    )
}

export default EventStepCard;