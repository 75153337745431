import React from "react";
import { Link } from "react-router-dom";
import single from "../assets/images/single.png";
import multiple from "../assets/images/multiple.png";

function RoleBar(props) {
    const { role } = { ...props };
    return (
        <Link to={`/roles/${role.id}`}>
            <div className="role-bar">
                <img src={role.multiple_people ? multiple : single} alt={`${role.title} Icon`}/>
                <h3>{role.title}</h3>
            </div>
        </Link>
    )
}

export default RoleBar;