import React from "react";
import EventBar from "../components/EventBar";

function EventsPage(props) {
    const { content } = { ...props };

    return (
        <div className="events">
            {content.map(event => <EventBar key={event.id} event={event}/>)}
        </div>
    )
}

export default EventsPage;