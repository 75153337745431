import React from "react";
import { useParams } from "react-router-dom";
import SliderContainer from "../components/SliderContainer";
import EventCard from "../components/EventCard";

function EventDetailPage(props) {
    const { content, steps } = { ...props };
    const params = useParams();
    const id = params.id;
    const event = content.find(event => event.id === id);
    const initialIndex = event ? content.indexOf(event) : 0;

    return (
        <SliderContainer initial={initialIndex}>
            {content.map((event, idx) => <EventCard key={idx} event={event} steps={steps} />)}
        </SliderContainer>
    )
}

export default EventDetailPage;