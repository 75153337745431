import React from "react";
import { Link, useLocation } from "react-router-dom";

function NavbarButton(props) {
    const { path, label } = { ...props };
    const location = useLocation();
    const { pathname } = { ...location }
    const topLevelPath = `/${pathname.split("/")[1]}`;

    return (
        <Link to={path}>
            <div className="nav-button" style={{backgroundColor: pathname === path || topLevelPath === path ? "#1B75BB" : "white"}}>
                <p style={{color: pathname === path || topLevelPath === path ? "white" : "black"}}>{label}</p>
            </div>
        </Link>
    )
}

export default NavbarButton;