import React from "react";
import { Link } from "react-router-dom";

function AnnexBar(props) {
    const { content, contentType } = { ...props };
    if(contentType === "annex") {
        return (
            <div className="annex-bar main-header">
                <h3>ANNEX {content.letter} - {content.title}</h3>
            </div>
        )
    }
    else if(contentType === "appendix") {
        return (
            <div className="annex-bar main-header">
                <h3>{content.title}</h3>
            </div>
        )
    }
    else if(contentType === "section") {
        return (
            <Link to={`${content.annex_letter ? "annex_section" : "appendix_section"}/${content.id}`}>
                <div className="annex-bar">
                    <div>
                        <p>{content.title}</p>
                        {content.annex_letter && 
                            <p>{content.annex_letter}.{content.section_number}</p>
                        }
                    </div>
                </div>
            </Link>
        )
    }
}

export default AnnexBar;