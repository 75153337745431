import React from "react";
import { Link } from "react-router-dom";

function EventCard(props) {
    const { event, steps } = { ...props };
    const stepTypes = steps.types.filter(el => event.id in steps.steps && el.id in steps.steps[event.id]);
    return (
        <div className="event-card slider-card center">
            <h3>{event.title}</h3>
            <img src={`data:image/png;base64,${event.icon}`} alt={`${event.title} Icon`}/>
            <div>
                <div className="icons-container">
                    {stepTypes.map((t, idx) =>
                        <Link to={`/events/${event.id}/steps/${t.id}`} key={idx} tabIndex={-1}>
                            <div> 
                                <img key={t.id} src={`data:image/png;base64,${t.icon}`} alt={`${event.title} ${t.name} icon`}/>
                                <h6>{t.name}</h6>
                            </div>
                        </Link> 
                    )}
                </div>
                <h6>SECTION</h6>
                <p>{event.appendix_section}</p>
                <h6>DESCRIPTION</h6>
                <p>{event.description}</p>
                <h6>DETAILED DESCRIPTION</h6>
                <p>{event.detailed_description}</p>
            </div>
        </div>
    )
}

export default EventCard;