import React from "react";
import { useLocation } from "react-router-dom";

function FeedbackButton() {
    useLocation();
    return (
        <div className="feedback">
            <a href={process.env.REACT_APP_FEEDBACK_FORM_LINK + window.location.href} target="_blank" rel="noopener noreferrer">Feedback?</a>
        </div>
    )
}

export default FeedbackButton;